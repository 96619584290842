import React from "react";
// Customizable Area Start
import {
    Button,
    Grid,
    Box,
    Paper,
    Typography,
    FormControlLabel,
    Radio,
    RadioGroup
} from "@material-ui/core";
import {Alert, Snackbar, TextField, Select, MenuItem, Checkbox } from "@mui/material";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
    barIcon,
    restaurantIcon,
    wineryIcon,
    breweryIcon,
    backgroundImg,
    barChart,
    UploadImgIcon,
    reviewStartIcon,
    businessLogo,
    barIconUnselected, wineryIconUnselected, breweryIconUnselected, restaurantIconUnselected,
    leftArrow,
    RightArrow,
    CloseBtn,
    UplaodImgBtn,
    btnCancel
} from "./assets";
import Header from "../../navigationmenu/src/Header.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu";
import "./CustomisableUserProfiles.css";
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import VisualAnalytics from "../../visualanalytics/src/VisualAnalytics.web";
import ButtonGroup from '@mui/material/ButtonGroup';
// Customizable Area End

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

import CustomisableBusinessController, {
    Props,
    Weekday
} from "./CustomisableBusinessController";


// Customizable Area End

export default class CustomisableBusinessProfile extends CustomisableBusinessController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    icons = [barIcon, wineryIcon, breweryIcon, restaurantIcon];
    iconsUnselected = [barIconUnselected, wineryIconUnselected, breweryIconUnselected, restaurantIconUnselected]

    renderIconAndTypography = (tops:string, index:number) => {
        const isCategorySelected = this.state.catgory_name === tops;
        const iconSrc = isCategorySelected ? this.icons[index] : this.iconsUnselected[index];
        const typoClassName = isCategorySelected ? "yourTopsTypo" : "yourTopsTypo yourTopsTypoUnselected";
    
        return (
            <div style={{ display: "flex", alignItems: "center"}} key={index}>
                <img src={iconSrc} alt={`Icon ${index}`} className="tops-icon-style"/>
                <Typography className={typoClassName}>
                    {tops}
                </Typography>
            </div>
        );
    };

    formatLabel(category:string) {
        const index = category.indexOf('(');
        if (index !== -1) {
            const part1 = category.substring(0, index);
            const part2 = category.substring(index);
            return (
                <>
                    {part1}<br/>
                    <label>{part2}</label>
                </>
            );
        }
        return category;
    }

    renderClassName = (value: string, defaultValue: string) => {
        return this.state.editMode || this.state.subscription === "THE HOUSE POUR" ? value : defaultValue;
    };

    renderHelpUsButton() {
        const { editHelpusMode, editMode } = this.state;
        
        if (editHelpusMode || editMode) {
            return (
                <Button className="upload-img-btn-business" onClick={this.editHelpUSHelpYou}>
                    Submit
                </Button>
            );
        }
    
        return (
            <Button className="yourfilter-edit-btn" onClick={this.handleHelpUsClick}>
                Edit
            </Button>
        );
    }

    getPaperWrapperClass = (subscription:string) => {
        return (subscription === "THE HOUSE POUR" || subscription === "THE WELL") 
          ? "eventPaperWrapper disabled" 
          : "eventPaperWrapper";
    };   

    getReviewsWrapperClass = (subscription:string) => {
        return (this.state.editMode ||  subscription === "THE HOUSE POUR" || subscription === "THE WELL") 
          ? "reviewsWrapper disabled" 
          : "reviewsWrapper";
    };  
    
    renderCustomerImagesPermission = (subscription:string, allowCustomImages:boolean) => {
        if (subscription === "THE HOUSE POUR") return null;
      
        return (
          <Box className="permission-switch-container">
            <Typography className="permission-switch-typo">
              Allow customer images to be viewed on my page
            </Typography>
            <Switch
              className="switch"
              data-test-id="imageAllow"
              size="small"
              checked={allowCustomImages}
              onChange={this.handlePermissionChange}
            />
          </Box>
        );
      };


      getButtonGroup = (subscription:string) =>{
        return (
            <ButtonGroup variant="text"
            aria-label="subscription tabs"
            style={{
                borderRadius: "8px",
                border: "2px solid #FFF",
            }}>
            <Button
                style={{
                    ...webStyle.btnGroupStyle,
                    backgroundColor:
                        subscription === "THE HOUSE POUR" || subscription === "THE WELL" || subscription === "TOP SHELF"
                            ? "#DA9A22"
                            : "#FFFFFF",
                    color:
                        subscription === "THE HOUSE POUR" || subscription === "THE WELL" || subscription === "TOP SHELF"
                            ? "#FFFFFF"
                            : "#712220",
                    borderBottomLeftRadius:"4px",
                    borderTopLeftRadius:"4px",
                }}
            >
                THE HOUSE POUR
            </Button>
            <Button
                style={{
                    ...webStyle.btnGroupStyle,
                    backgroundColor: subscription === "THE WELL" || subscription === "TOP SHELF" ? "#C33836" : "#FFFFFF",
                    color:
                    subscription === "THE WELL" || subscription === "TOP SHELF"
                        ? "#FFFFFF"
                        : "#712220",
                }}
            >
                THE WELL
            </Button>
            <Button
                style={{
                    ...webStyle.btnGroupStyle,
                    backgroundColor: subscription === "TOP SHELF" ? "#9C0E2E" : "#FFFFFF",
                    color: subscription === "TOP SHELF" ? "#FFFFFF" : "#712220",
                    borderBottomRightRadius:"4px",
                    borderTopRightRadius:"4px",
                }}
            >
                TOP SHELF
            </Button>
        </ButtonGroup>
        )
      }

    getMaxUploads = (subscription:string) => {

        if (subscription === "THE HOUSE POUR") {
            return 3;
        } else if (subscription === "THE WELL") {
            return 10;
        } else if (subscription === "TOP SHELF") {
            return 20;
        }

        return 0; 
    };

      
    render() {
        // Customizable Area Start

        const maxUploads = this.getMaxUploads(this.state.subscription);
        const canUploadMore = this.state.image_library.length < maxUploads;
   
        const buttonText = (this.state.editMode || this.state.editInfoMode) ? "Submit" : "Edit All";
        const imageUrl = this.state.imageUrl ? this.state.imageUrl : businessLogo;
       
        const renderInfo = (value: string, defaultValue: string) => (
            <Typography style={webStyle.profileInfo} gutterBottom>
              {value ? value : defaultValue}
            </Typography>
        );  

        const renderTimeField = (setTimesKey: 'setTimes' | 'setTimes2', openingDaysKey: 'openingDays' | 'openingDays2') => {
            return weekdays.map((day) => (
                <Box key={day} display="flex" alignItems="center">
                    {this.state[openingDaysKey][day] ? (
                        <TextField
                            type="time"
                            className="input-time-field"
                            value={this.state[setTimesKey][day]?.closesAt || ''}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        borderColor: "#712220",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "#712220",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#712220",
                                    },
                                    "& .MuiInputBase-input": {
                                        padding: "0.833vw 1.111vw",
                                        fontSize: "1.111vw",
                                    },
                                },
                            }}
                            onChange={this.handleTimeChange(setTimesKey, day, 'closesAt')}
                            fullWidth
                        />
                    ) : (
                        <Typography className="popup-closed-typo-2"></Typography>
                    )}
                </Box>
            ));
        };
        

        const renderErrorMessage = (errorMessage : string) => {
            return errorMessage ? <div className="invalid-error-barowner">{errorMessage}</div> : null;
        }
        
        const imagesToShow = this.state.customer_images.slice(0, 6);
        const placeholders = Array.from({ length: 6 - imagesToShow.length });   
        
        const businessImagesToShow = this.state.image_library.slice(0, 6);
        const businessPlaceholders = Array.from({ length: 6 - businessImagesToShow.length }); 

        const likedByAccountToShow = this.state.likedByAccounts.slice(0, 6);
        const likedPlaceholders = Array.from({ length: 6 - likedByAccountToShow.length }); 

        const { currentPhotoIndex, image_library , currentIndex, eventsPerPage, selectedPhotoIndex, establishmentOffer1Data, establishmentOffer2Data, subscription} = this.state;
        const currentImage = image_library[currentPhotoIndex];
        const displayedEvents = image_library.slice( currentIndex, currentIndex + eventsPerPage );
        const imagePlaceholders = Array.from({ length: 5 - displayedEvents.length }); 

        const weekdays: Weekday[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

        return (
            <ThemeProvider theme={theme}>
                <Box style={{ marginTop: "5.625vw", overflowX: "hidden" }}>
                    <Header {...this.props} />
                    <Box style={webStyle.backgroundImg}>
                        <Box style={webStyle.mainContainer}>
                            <Grid container spacing={2} justifyContent="center" alignItems='center'>
                                <Grid item xs={12}>
                                    <Box sx={webStyle.btnContainer}>
                                        <Button
                                            data-test-id={"editButtonBusiness"}
                                            className="edit-all-btn"
                                            onClick={(this.state.editMode || this.state.editInfoMode) ? this.editAllBusinessCustomisableProfile : this.handleBusinessEditClick }
                                        >
                                            {buttonText}
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={8}>
                                    <Paper className="businessProfilePaper">
                                        <Box className={this.state.editMode || this.state.editInfoMode? "businessProfileContainer1":"businessProfileContainer"}>
                                            <Box className="business-info-1-2-3-container">
                                                <Box className="business-info-1-2-container">
                                                    <Box className="business-info-1">
                                                        <div className="container-business">
                                                            <img src={imageUrl} alt="Image" style={webStyle.imgStyle2} />
                                                            <Button className="buttonStyle-business">
                                                                <input type="file" id="uploadPicture" data-test-id="uploadImageProfile" style={{ display: 'none' }} onChange={this.handleBusinessUploadImgChange} />
                                                                <label htmlFor="uploadPicture">
                                                                    <img style={{ cursor: 'pointer', width:"1.389vw"  }} src={UploadImgIcon}></img>
                                                                </label>
                                                            </Button>
                                                            <Snackbar open={this.state.successSnackbarUpload} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                                                                <Alert
                                                                    severity="success"
                                                                    variant="filled"
                                                                    sx={{ width: '100%', height: '50%', padding: '0px 12px 0px 12px' }}
                                                                >
                                                                    Image Uploaded Successfully!
                                                                </Alert>
                                                            </Snackbar>
                                                        </div>
                                                        <Typography noWrap style={{ ...webStyle.profileInfo, marginTop: "0.556vw", fontWeight: 500 }}>Joined on </Typography>
                                                        <Typography noWrap style={{ ...webStyle.profileInfo, marginBottom: "0.347vw", fontWeight: 500 }}>{this.state.business_joined_in}</Typography>
                                                    </Box>
                                                    <Box className={this.state.editMode || this.state.editInfoMode ? "business-info-2 space":"business-info-2"}>
                                                        {this.state.editMode || this.state.editInfoMode ? (
                                                            <>
                                                                <TextField
                                                                    variant="outlined"
                                                                    
                                                                    size="small"
                                                                    placeholder="Name of Business"
                                                                    className="formInputProfile"
                                                                    value={this.state.business_name}
                                                                    style={webStyle.profileInfo}
                                                                    data-test-id={"txtInputName"}
                                                                    name="business_name"
                                                                    onChange={(e) =>
                                                                        this.handleBusinessInputChange(e.target.name, e.target.value)}
                                                                />
                                                                {renderErrorMessage(this.state.invalidBusinessName)}
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    placeholder="Contact Name"
                                                                    className="formInputProfile"
                                                                    value={this.state.business_contact}
                                                                    style={webStyle.profileInfo}
                                                                    data-test-id={"txtInputContactName"}
                                                                    name="business_contact"
                                                                    onChange={(e) =>
                                                                        this.handleBusinessInputChange(e.target.name, e.target.value)}
                                                                />
                                                                 {renderErrorMessage(this.state.invalidNumber1)}
                                                                <TextField
                                                                    variant="outlined"
                                                                    
                                                                    size="small"
                                                                    placeholder="Email"
                                                                    className="formInputProfile"
                                                                    value={this.state.business_email}
                                                                    style={webStyle.profileInfo}
                                                                    data-test-id={"txtInputEmail"}
                                                                    name="business_email"
                                                                    onChange={(e) =>
                                                                        this.handleBusinessInputChange(e.target.name, e.target.value)}
                                                                />
                                                                {renderErrorMessage(this.state.invalidEmail)}
                                                                <TextField
                                                                    variant="outlined"
                                                                    
                                                                    size="small"
                                                                    placeholder="Website"
                                                                    className="formInputProfile"
                                                                    value={this.state.business_website}
                                                                    style={webStyle.profileInfo}
                                                                    data-test-id={"txtInputWebsite"}
                                                                    name="business_website"
                                                                    onChange={(e) =>
                                                                        this.handleBusinessInputChange(e.target.name, e.target.value)}
                                                                />
                                                                {renderErrorMessage(this.state.invalidWebsite)}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {renderInfo(this.state.business_name, "Business Name")}
                                                                {renderInfo(this.state.business_contact, "Contact Name")}
                                                                {renderInfo(this.state.business_email, "Email")}
                                                                {renderInfo(this.state.business_website, "Website")}
                                                            </>
                                                        )}
                                                    </Box>
                                                </Box>
                                                <Box className={this.state.editMode || this.state.editInfoMode ? "business-info-3 space":"business-info-3"}>
                                                    {this.state.editMode || this.state.editInfoMode ? (
                                                        <>
                                                            <TextField
                                                                variant="outlined"
                                                                size="small"
                                                                placeholder="Business Phone"
                                                                className="formInputProfile"
                                                                inputProps={{ maxLength: 10 }}
                                                                value={this.state.business_phone}
                                                                style={webStyle.profileInfo}
                                                                data-test-id={"txtInputphone"}
                                                                name="business_phone"
                                                                onChange={(e) =>
                                                                    this.handleBusinessInputChange(e.target.name, e.target.value)}
                                                            />
                                                             {this.state.invalidNumber2 && <div className="invalid-error-barowner">Phone number must be 10 digits</div>}
                                                            <TextField
                                                                variant="outlined"
                                                                size="small"
                                                                placeholder="Main Contact Phone"
                                                                className="formInputProfile"
                                                                inputProps={{ maxLength: 10 }}
                                                                value={this.state.business_main_contact}
                                                                data-test-id={"txtInputMainContact"}
                                                                name="business_main_contact"
                                                                style={webStyle.profileInfo}
                                                                onChange={(e) =>
                                                                    this.handleBusinessInputChange(e.target.name, e.target.value)}
                                                            />
                                                            {this.state.invalidNumber3 && <div className="invalid-error-barowner">Phone number must be 10 digits</div>}
                                                            <TextField
                                                                variant="outlined"
                                                                size="small"
                                                                placeholder="Address"
                                                                className="formInputProfile"
                                                                value={this.state.business_address}
                                                                data-test-id={"txtInputAddress"}
                                                                name="business_address"
                                                                style={webStyle.profileInfo}
                                                                onChange={(e) =>
                                                                    this.handleBusinessInputChange(e.target.name, e.target.value)}
                                                            />
                                                             <Box className="txtFieldWrapper">
                                                                <TextField
                                                                    variant="outlined"
                                                                    placeholder="City"
                                                                    size="small"
                                                                    className="formInputProfile"
                                                                    value={this.state.city_name}
                                                                    data-test-id={"txtInputCity"}
                                                                    fullWidth
                                                                    style={webStyle.profileInfo}
                                                                    name="city_name"
                                                                    onChange={(e) =>
                                                                        this.handleBusinessInputChange(e.target.name, e.target.value)}
                                                                />
                                                                <TextField
                                                                    variant="outlined"
                                                                    placeholder="State"
                                                                    size="small"
                                                                    className="formInputProfile"
                                                                    data-test-id={"txtInputState"}
                                                                    value={this.state.state_name}
                                                                    fullWidth
                                                                    style={webStyle.profileInfo}
                                                                    name="state_name"
                                                                    onChange={(e) =>
                                                                        this.handleBusinessInputChange(e.target.name, e.target.value)}
                                                                />
                                                                <TextField
                                                                    variant="outlined"
                                                                    placeholder="Zip"
                                                                    size="small"
                                                                    inputProps={{ maxLength: 5 }}
                                                                    className="formInputProfile"
                                                                    value={this.state.country_code}
                                                                    data-test-id={"txtInputZip"}
                                                                    fullWidth
                                                                    name="country_code"
                                                                    style={webStyle.profileInfo}
                                                                    onChange={(e) =>
                                                                        this.handleBusinessInputChange(e.target.name, e.target.value)}
                                                                />
                                                            </Box>
                                                            {renderErrorMessage(this.state.invalidCountryCode)}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {renderInfo(this.state.business_phone, "Business Phone")}
                                                            {renderInfo(this.state.business_main_contact, "Main Contact Phone")}
                                                            {renderInfo(this.state.business_address, "Address")}
                                                        </>
                                                    )}
                                                </Box>
                                            </Box>
                                            {!(this.state.editMode || this.state.editInfoMode) && (
                                                <Button className="grid-1-edit-btn"  onClick={this.handleBusinessPersonalInfoClick}>
                                                    Edit
                                                </Button>
                                            )}
                                            <Snackbar open={this.state.dataEditedPopup} autoHideDuration={1000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                                                <Alert
                                                    severity="success"
                                                    variant="filled"
                                                    className="alert-submission"
                                                    sx={{ width: '100%', height: '50%', padding: '0px 12px 0px 12px' }}
                                                >
                                                    Data Edited Successfully!
                                                </Alert>
                                            </Snackbar>
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={4}>
                                    <Paper className={this.renderClassName("itemStyleTotalViews disabled", "itemStyleTotalViews")} >
                                        <Typography className="topThreeCheckinsHeading" style={webStyle.itemHeading}>Total Views</Typography>
                                        <div className="totalViews">{this.state.totalViews}</div>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={webStyle.subscriptionContainer}>
                                        <Typography style={webStyle.subscriptionHeading}>Subscription Level</Typography>
                                        <Box sx={webStyle.subscriptionBtnContainer}>
                                            <Typography style={webStyle.unlockHeading}>{this.state.featuresUnlocked} of 12 Features Unlocked</Typography>
                                           {this.getButtonGroup(subscription)}
                                        </Box>
                                        <Button style={webStyle.upgradeBtn}> UPGRADE </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Paper className={this.renderClassName("wrapper-2-Paper itemStyleTotalCheckIn disabled", "wrapper-2-Paper itemStyleTotalCheckIn")} >
                                        <Typography className="topThreeCheckinsHeadingBusiness" style={webStyle.itemHeading}>Total Check-ins to Date</Typography>
                                        <VisualAnalytics {...this.props}/>
                                        <Box className="itemHeadingTotalBusiness">
                                            <Typography className="totalCheckHeading">TOTAL</Typography>
                                            <Typography className="totalCheckDesBusiness">{this.state.totalCheckIns}</Typography>
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={4}>
                                    <Paper className="wrapper-2-Paper itemStyleLibrary">
                                        <Box className="itemStyleCustomerHeading itemStyleCustomer">
                                            <Typography style={webStyle.itemHeading}>Image Library</Typography>
                                            <Typography className="see-all-typo pointer" onClick={this.handleClickOpen}>Edit</Typography>
                                        </Box>
                                        <Box className="itemGridBoxCustomer itemStyleCustomer" sx={{ flexGrow: 1 }}>
                                            {businessImagesToShow.map((image, index) => (
                                                <Paper className="businessImageContainer" key={index}>
                                                    <img style={webStyle.imgGridLibrary} src={image.url} alt={image.filename} />
                                                </Paper>
                                            ))}
                                            {businessPlaceholders.map((_, index) => (
                                                <Paper className="businessImageContainer businessimagePaceholder" key={index}></Paper>
                                            ))}
                                        </Box>
                                        <Box className="seeall-upload-container">
                                            <Typography className="see-all-typo">See all</Typography>
                                            <Button className="upload-img-btn-business" disabled={!canUploadMore}>
                                                <input
                                                    type="file"
                                                    id="profilePicture1"
                                                    multiple
                                                    className=""
                                                    data-test-id="uploadImageBtn"
                                                    style={{ display: 'none' }}
                                                    disabled={!canUploadMore} 
                                                    onChange={this.handleFileChange}
                                                />
                                                <label htmlFor="profilePicture1">
                                                    Upload
                                                </label>
                                            </Button>
                                            <Dialog className="pop-up-dialog-barowner" open={this.state.setOpen} onClose={this.handleClickClose}>
                                                <DialogTitle id="customized-dialog-title" className="upload-popup-title">
                                                    {currentImage?.image_name}
                                                </DialogTitle>
                                                <IconButton
                                                    aria-label="close"
                                                    onClick={this.handleClickClose}
                                                    sx={{
                                                        position: 'absolute',
                                                        right: "0.556vh",
                                                        padding:0,
                                                        top: "0.556vh",
                                                    }}
                                                >
                                                    <img className="upload-popup-close" src={CloseBtn}></img>
                                                </IconButton>
                                                <DialogContent className="popup-content-wrapper">
                                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Button className="no-style-btn" onClick={this.handlePrevImage} disabled={currentPhotoIndex === currentIndex}>
                                                            <img className="popup-arrow-btn left-btn" src={leftArrow}></img>
                                                        </Button>
                                                        {
                                                            currentImage ?  <img src={currentImage?.url} alt={currentImage?.filename} className="image-center-popup" />:
                                                            <div className="image-center-popup-placeholder">Add an image</div>
                                                        }                            
                                                        <Button className="no-style-btn" onClick={this.handleNextImage} disabled={currentPhotoIndex === currentIndex + displayedEvents.length - 1}>
                                                            <img className="popup-arrow-btn right-btn" src={RightArrow}></img>
                                                        </Button>
                                                    </Box>
                                                    <Box style={{ display: "flex", justifyContent: "space-between", padding: "1.667vh 1.667vh", marginTop: "-6.1vh" }}>
                                                        <Button className="upload-popup-btns" onClick={this.handleClickOpenEditPopup}>
                                                            Edit
                                                        </Button>
                                                        <Snackbar open={this.state.successSnackbarEdit} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                                                            <Alert
                                                                severity="success"
                                                                variant="filled"
                                                                sx={{ width: '100%', height: '50%', padding: '0px 12px 0px 12px' }}
                                                            >
                                                                Image Data Edited Successfully!
                                                            </Alert>
                                                        </Snackbar>
                                                        <Dialog className="pop3-up-dialog-business" open={this.state.setOpenEditPopup} onClose={this.handleCloseEditPopup}>
                                                            <DialogActions className="pop-up-dialog-action-catalogue">
                                                                <Button className="pop-up-btn-catalogue" data-test-id="handleCloseBtn3" onClick={this.handleCloseEditPopup} color="primary">
                                                                    <img className="cancel-popup-catalogue" src={btnCancel} alt="image-close"></img>
                                                                </Button>
                                                            </DialogActions>
                                                            <DialogContent className="popup-dialog-edit-content" >
                                                                <Box >
                                                                    <TextField
                                                                        data-test-id="textAreaEditTitle"
                                                                        variant="outlined"
                                                                        value={this.state.imgTitle}
                                                                        sx={{
                                                                            "& .MuiOutlinedInput-root": {
                                                                                height: "3.125vh",
                                                                                width: "27.917vh",
                                                                                "& fieldset": {
                                                                                    borderColor: "#712220",
                                                                                },
                                                                                "&:hover fieldset": {
                                                                                    borderColor: "#712220",
                                                                                },
                                                                                "&.Mui-focused fieldset": {
                                                                                    borderColor: "#712220",
                                                                                },
                                                                                "& .MuiOutlinedInput-input": {
                                                                                    fontSize: "1.111vh",
                                                                                },
                                                                            },
                                                                        }}
                                                                        fullWidth
                                                                        placeholder="Image Title"
                                                                        onChange={(event) => this.handleTitleChangeImage(event.target.value)}
                                                                    />
                                                                </Box>
                                                                <Box className="popup-description-box">
                                                                    <TextField
                                                                        data-test-id="textAreaEditLabel"
                                                                        multiline
                                                                        className="edit-popup-textfield"
                                                                        value={this.state.imgDescription}
                                                                        placeholder="Image description - max 25 words"
                                                                        sx={{
                                                                            "& .MuiOutlinedInput-root": {
                                                                                height: "9.444vh",
                                                                                width: "32.5vh",
                                                                                alignItems:"flex-start",
                                                                                lineHeight:'2vh',
                                                                                paddingTop:"0.694vh",
                                                                                "& fieldset": {
                                                                                    borderColor: "#712220",
                                                                                },
                                                                                "&:hover fieldset": {
                                                                                    borderColor: "#712220",
                                                                                },
                                                                                "&.Mui-focused fieldset": {
                                                                                    borderColor: "#712220",
                                                                                },
                                                                                "& .MuiOutlinedInput-input": {
                                                                                    fontSize: "1.111vh",
                                                                                },
                                                                            },
                                                                        }}
                                                                        onChange={(event) => this.handleDescriptionChangeImage(event.target.value)}
                                                                    />
                                                                    {!!this.state.invalidDescription && <div className="invalid-desc-error">{this.state.invalidDescription}</div>}
                                                                </Box>
                                                            </DialogContent>
                                                            <Box className="edit-save-popup">
                                                                <Button data-test-id="editBtn" type="submit" className="btnSaveEditPopup" onClick={() => this.editBusinessImages(currentImage?.id)}>Save</Button>
                                                            </Box>
                                                        </Dialog>
                                                        <Button className="upload-popup-btns" onClick={this.handleClickOpenDeletePopup}>
                                                            Delete
                                                        </Button>
                                                        <Dialog className="pop3-up-dialog-business" open={this.state.setOpenDeletePopup} onClose={this.CloseDeletePopup}>
                                                            <DialogActions className="pop-up-dialog-action-catalogue">
                                                                <Button className="pop-up-btn-catalogue" data-test-id="handleCloseBtn3" onClick={this.CloseDeletePopup} color="primary">
                                                                    <img className="cancel-popup-catalogue" src={btnCancel} alt="image-close"></img>
                                                                </Button>
                                                            </DialogActions>
                                                            <DialogTitle className="pop-up-dialog-title-delete">
                                                                <div className="pop-up-dialog-title-delete-text">Are you sure you want to</div>
                                                                <div className="pop-up-dialog-title-delete-text">delete this image?</div> 
                                                            </DialogTitle>
                                                            <DialogActions className="pop-up-dialog-action-delete">
                                                                <Button type="submit" className="btnDeleteYes"  data-test-id="deleteBtn"  onClick={() => this.deleteImage(currentImage?.id)}>Yes</Button>
                                                            </DialogActions>
                                                        </Dialog>
                                                        <Snackbar open={this.state.successSnackbarDeleted} autoHideDuration={2000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                                                            <Alert
                                                                severity="success"
                                                                variant="filled"
                                                                sx={{ width: '100%', height: '50%', padding: '0px 12px 0px 12px' }}
                                                            >
                                                                Image Deleted Successfully!
                                                            </Alert>
                                                        </Snackbar>
                                                    </Box>
                                                <Box className="popup-content-grid-wrapper">
                                                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                                                        <Button className="no-style-btn" onClick={this.handlePreviousClick} disabled={currentIndex === 0}>
                                                            <img className="popup-arrow-btn" src={leftArrow}></img>
                                                        </Button>
                                                            <Box className="uploadbtn-imggrid-wrapper">
                                                                <Button className="no-style-btn">
                                                                    <input
                                                                        type="file"
                                                                        id="profilePicture"
                                                                        multiple 
                                                                        data-test-id="uploadImage"
                                                                        style={{ display: 'none' }}
                                                                        onChange={this.handleFileChange}
                                                                    />
                                                                    <label htmlFor="profilePicture">
                                                                        <img className="upload-img-btn-popup" src={UplaodImgBtn}></img>
                                                                    </label>
                                                                </Button>
                                                                <Snackbar open={this.state.successSnackbarUpload} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                                                                    <Alert
                                                                        severity="success"
                                                                        variant="filled"
                                                                        sx={{ width: '100%', height: '50%', padding: '0px 12px 0px 12px' }}
                                                                    >
                                                                        Image Uploaded Successfully!
                                                                    </Alert>
                                                                </Snackbar>
                                                            {displayedEvents.map((event, index) => (
                                                                <img
                                                                    key={index}
                                                                    src={event?.url}
                                                                    alt={event?.filename}
                                                                    className="image-grid-popup"
                                                                    data-test-id="imageClick"
                                                                    style={{ border: selectedPhotoIndex === index + currentIndex ? '4px solid #DA9A22' : 'none' }}
                                                                    onClick={() => this.handleImageClick(index + currentIndex)}
                                                                />
                                                            ))}
                                                            {imagePlaceholders.map((_, index) => (
                                                                <Box className="image-grid-popup-placeholder" key={index}></Box>
                                                            ))}
                                                        </Box>
                                                        <Button className="no-style-btn" onClick={this.handleClickNext} disabled={currentIndex >= image_library.length - eventsPerPage}>
                                                            <img className="popup-arrow-btn" src={RightArrow}></img>
                                                        </Button>
                                                    </Box>
                                                </Box>
                                                </DialogContent>
                                            </Dialog>
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={4} >
                                <Paper className={this.renderClassName("wrapper-2-Paper itemStyleLikes disabled", "wrapper-2-Paper itemStyleLikes")} >
                                        <Box className="itemHeadingSeeAllBusiness">
                                            <Typography style={webStyle.itemHeading}>Likes</Typography>
                                            <Typography className="see-all-typo">See all</Typography>
                                        </Box>
                                        <Box className="itemGridBoxBusiness" sx={{ flexGrow: 1 }}>
                                            {likedByAccountToShow.map((account, index) => (
                                                <img style={{ width: '5.77vw', height: '5.77vw', borderRadius: "50%", objectFit: "cover", overflow: "hidden" }} src={account.avatar.url} alt={`Friend ${index + 1}`} />
                                            ))}
                                            {likedPlaceholders.map((_, index) => (
                                                <Box style={{ width: '5.77vw', height: '5.77vw', borderRadius: "50%", objectFit: "cover", overflow: "hidden", border:"1px solid #712220" }} key={index}></Box>
                                            ))}
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={4}>
                                    <Paper className="menuPaperWrapper" >
                                        <Box className="itemStyleMenuHeading">
                                            <Typography style={webStyle.itemHeading}>Menu</Typography>
                                        </Box>
                                        <Box sx={{ flexGrow: 1 }} className="menuContentWrapper" >
                                            <Grid container spacing={1}>
                                                <Grid container item spacing={1}>
                                                    <Grid item xs={4}>
                                                        <Paper className="businessMenuContainer1">
                                                            <div className="menu-type-1">GOOD</div>
                                                            <div className="menu-des-1">Image</div>
                                                            <div className="menu-des-1">of Menu</div>
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Paper className="businessMenuContainer2">
                                                            <div className="menu-type-2">BETTER</div>
                                                            <div className="menu-des-2">Pick from</div>
                                                            <div className="menu-des-2">3 Templates</div>
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Paper className="businessMenuContainer3">
                                                            <div className="menu-type-3">CUSTOM</div>
                                                            <div className="menu-des-3">Fully Custom</div>
                                                        </Paper>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={8}>
                                    <Grid container spacing={2}>
                                        <Grid container item spacing={3}>
                                            <Grid item xs={12}>
                                                <Paper className="categoryWrapper">
                                                    <Box className="type-category-edit-wrapper">
                                                      
                                                        <Box>
                                                            <Box className="itemHeadingSeeAllBusiness">
                                                                <Typography style={webStyle.itemHeading}>Business Type</Typography>
                                                            </Box>
                                                            <Box style={{ height: "9.167vw", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                                                                {this.state.editHelpusMode || this.state.editMode? (
                                                                    <Box>
                                                                        <RadioGroup
                                                                            row
                                                                            style={{ height: "9.167vw"}}
                                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                                            defaultValue="f"
                                                                            name="radio-buttons-group"
                                                                            onChange={this.handleCategoryChange}
                                                                        >
                                                                            {this.state.categoryData.map((category, index) => (
                                                                                <FormControlLabel
                                                                                    key={index}
                                                                                    value={category}
                                                                                    className="label-style"
                                                                                    control={
                                                                                        <Radio
                                                                                            icon={<span className="radioBox2" />}
                                                                                            checked={this.state.catgory_name.includes(category)}
                                                                                            checkedIcon={<span className="radioBox2 checked" />}
                                                                                            style={webStyle.radioBox}
                                                                                        />
                                                                                    }
                                                                                    label={<Typography className="formControlCategory" >{category}</Typography>}
                                                                                />
                                                                            ))}
                                                                        </RadioGroup>
                                                                    </Box>
                                                                ) : (
                                                                    <>
                                                                        {this.state.categoryData.map((tops, index) => (
                                                                            this.renderIconAndTypography(tops, index)
                                                                        ))}
                                                                    </>
                                                                )}

                                                            </Box>
                                                        </Box>
                                                        <Box style={{ display: "flex", marginLeft: "1.097vw" }}>
                                                            <Box>
                                                                <Box className="itemHeadingSubCategoryBusiness">
                                                                    <Typography style={webStyle.itemHeading}>Subcategories</Typography>
                                                                </Box>
                                                                <Box className="category-2-1-business">
                                                                    {
                                                                        this.state.subCategoryData.map((subCategories, index) => (
                                                                            <Box
                                                                                className="category-2-1-box-business"
                                                                                key={subCategories}
                                                                            >
                                                                                <FormControlLabel
                                                                                    key={index}
                                                                                    data-test-id={subCategories}
                                                                                    value={subCategories}
                                                                                    className="form-control-label"
                                                                                    onClick={() => { (this.state.editMode || this.state.editHelpusMode) && this.handleSubCategoryChange(subCategories) }}
                                                                                    control={<Radio
                                                                                        icon={<span className="radioBox2" />}
                                                                                        checkedIcon={<span className="radioBox2 checked" />}
                                                                                        checked={this.state.subSelectedCategories.includes(subCategories)}
                                                                                        style={webStyle.radioBox} />}
                                                                                    label={<Typography className="formControl" >{subCategories}</Typography>}
                                                                                />
                                                                            </Box>
                                                                        ))
                                                                    }
                                                                </Box>
                                                            </Box>
                                                            <Box className="itemHeadingSubCategoryBusiness">
                                                                {this.renderHelpUsButton()}
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                        <Grid container item spacing={2}>
                                            <Grid item xs={6}>
                                                <Paper  className={this.getReviewsWrapperClass(subscription)} >
                                                    <Box className="itemStyleReviewHeading">
                                                        <Typography style={webStyle.itemHeading}>Non Public Check-in Reviews</Typography>
                                                    </Box>
                                                    <Box className="itemStyleReviewRating">
                                                        <Box className="rating-img-wrapper">
                                                            <div className="rating-typo" >{this.state.averageReviews}</div>
                                                            <img className="rating-img" src={reviewStartIcon}></img>
                                                        </Box>
                                                        <div className="total-reviews">{this.state.totalReviews} Total Reviews</div>
                                                    </Box>
                                                    <Box className="itemStyleReviewSeeall">
                                                        <Typography className="see-all-typo">See all</Typography>
                                                    </Box>
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={6}>
                                            <Paper className={this.renderClassName("customerImagesWrapper disabled", "customerImagesWrapper")} >
                                                    <Box className="itemStyleCustomerHeading itemStyleCustomer">
                                                        <Typography style={webStyle.itemHeading}>Customer Images</Typography>
                                                        <Typography className="see-all-typo">See all</Typography>
                                                    </Box>
                                                    <Box className="itemGridBoxCustomer itemStyleCustomer" sx={{ flexGrow: 1 }}>
                                                        {imagesToShow.map((image, index) => (
                                                            <Paper className="customerImageContainer" key={index}>
                                                                <img style={webStyle.imgGridLibrary} src={image.url} alt={image.filename} />
                                                            </Paper>
                                                        ))}
                                                        {placeholders.map((_, index) => (
                                                            <Paper className="customerImageContainer" key={index}></Paper>
                                                        ))}
                                                    </Box>
                                                    {this.renderCustomerImagesPermission(subscription, this.state.allowCustomImages)}
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Paper className="wrapper-2-Paper itemsStyleBusinessImportantFeature" >
                                        <Box className="itemStyleCustomerFiltersHeading">
                                            <Typography className="yourFiltersHeadingBusiness" style={webStyle.itemHeading}>Your Filters</Typography>
                                            {this.state.editFilterMode ? <Button className="upload-img-btn-business" onClick={this.editBusinessCustomisableProfile}>
                                                Submit
                                            </Button> :
                                                <Button className="yourfilter-edit-btn" onClick={this.handleOpenPopupFilter}>
                                                    Edit
                                                </Button>

                                            }
                                            <Dialog className="pop-up-dialog-amenities-catalogue-1" open={this.state.editFilterMode} onClose={this.handleCloseFilterPopup}>
                                                <DialogActions className="pop-up-dialog-action-catalogue">
                                                    <Button className="pop-up-btn-catalogue" data-test-id="handleCloseBtn1" onClick={this.handleCloseFilterPopup} color="primary">
                                                        <img className="cancel-popup-catalogue" src={btnCancel} alt="image-close"></img>
                                                    </Button>
                                                </DialogActions>
                                                <DialogContent className="pop-up-dialog-content-catalogue" >
                                                    <Box className="category-3-owner">
                                                        <Typography style={webStyle.starTextStyle}>
                                                            Select What Your Establishment Offers
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Box className="category-3-1">
                                                            {establishmentOffer1Data.map((item) => (
                                                                <Box className="category-3-1-formlabel" key={item.id}>
                                                                    <FormControlLabel
                                                                        value={item.name}
                                                                        className="label-style"
                                                                        data-test-id={item.name}
                                                                        onClick={() => {
                                                                            const currentValue = this.state.establishment_offer1[item.name];
                                                                            const isSelected = currentValue && currentValue[0] === item.name;

                                                                            if (isSelected) {
                                                                                this.onFormControlChange3(item.name, ['', this.state.establishment_offer1[item.name]?.[1] || '']);
                                                                            } else {
                                                                                this.onFormControlChange3(item.name, [item.name, this.state.establishment_offer1[item.name]?.[1] || '']);
                                                                            }
                                                                        }}
                                                                        control={
                                                                            <Radio
                                                                                icon={<span className="radioBox1" />}
                                                                                checkedIcon={<span className="radioBox1 checked" />}
                                                                                style={webStyle.radioBox1}
                                                                                checked={item.active}
                                                                            />
                                                                        }
                                                                        label={item.name}
                                                                    />

                                                                    {item.name === 'Other' ? (
                                                                        <TextField
                                                                            className="other-textfield"
                                                                            id="outlined-basic"
                                                                            variant="outlined"
                                                                            sx={{
                                                                                "& .MuiOutlinedInput-root": {
                                                                                    height: "3.125vw",
                                                                                    width: "13.264vw",
                                                                                    "& fieldset": {
                                                                                        borderColor: "#712220",
                                                                                    },
                                                                                    "&:hover fieldset": {
                                                                                        borderColor: "#712220",
                                                                                    },
                                                                                    "&.Mui-focused fieldset": {
                                                                                        borderColor: "#712220",
                                                                                    },
                                                                                    "& .MuiOutlinedInput-input": {
                                                                                        fontSize: "1.111vw",
                                                                                    },
                                                                                },
                                                                            }}
                                                                            size="small"
                                                                            placeholder="Enter here..."
                                                                            name="other"
                                                                            value={this.state.otherOffer}
                                                                            data-test-id="otherCategoryOffer"
                                                                            onChange={(event) => this.handleOtherCategoryChange2(event.target.value)}
                                                                        />
                                                                    ) : (
                                                                        <Select
                                                                            style={webStyle.dropDownStyle}
                                                                            variant="outlined"
                                                                            sx={{
                                                                                '.MuiOutlinedInput-notchedOutline': {
                                                                                    borderColor: "#712220",
                                                                                },
                                                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                                    borderColor: "#712220",
                                                                                },
                                                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                                    borderColor: "#712220",
                                                                                },
                                                                            }}
                                                                            className="establishment-offer-dropdown"
                                                                            data-test-id={item.id}
                                                                            labelId={`select-label-${item.id}`}
                                                                            onChange={(event) => {
                                                                                this.onFormControlChange3(item.name, [this.state.establishment_offer1[item.name]?.[0] || '', event.target.value]);
                                                                            }}
                                                                            multiple={item.value_type === 'String'}
                                                                            value={
                                                                                item.value_type === 'String'
                                                                                    ? (item.selected_options || [])
                                                                                    : (item.selected_options || '')
                                                                            }
                                                                        >
                                                                            {Array.isArray(item.selection_options)
                                                                                ? item.selection_options.map((option, index) => (
                                                                                    <MenuItem className="menu-item-establishment" key={index} value={option}>
                                                                                        {option}
                                                                                    </MenuItem>
                                                                                ))
                                                                                : item.selection_options.split(', ').map((option, index) => {
                                                                                    const displayOption = isNaN(Number(option)) ? option : Number(option);
                                                                                    return (
                                                                                        <MenuItem className="menu-item-establishment" key={index} value={displayOption}>
                                                                                            {displayOption}
                                                                                        </MenuItem>
                                                                                    );
                                                                                })}
                                                                        </Select>
                                                                    )}
                                                                </Box>
                                                            ))}



                                                        </Box>
                                                        <Box className="category-3-3">
                                                            <Box className="category-3-3-1">
                                                                {
                                                                    establishmentOffer2Data.map(category => (
                                                                        <div className="category-item" key={category.name}>
                                                                            <FormControlLabel
                                                                                value={category.name}
                                                                                className="label-style"
                                                                                data-test-id={category}
                                                                                onClick={() => this.onFormControlChange(category.name)}
                                                                                control={<Radio
                                                                                    checked={(category.active)}
                                                                                    icon={<span className="radioBox1" />}
                                                                                    checkedIcon={<span className="radioBox1 checked" />}
                                                                                    style={webStyle.radioBox1}
                                                                                />}
                                                                                label={this.formatLabel(category.name)}
                                                                            />

                                                                        </div>
                                                                    ))
                                                                }
                                                            </Box>
                                                        </Box>
                                                        <Box>
                                                            <Button data-test-id="submitBtn" className="submit-btn" onClick={() => this.editHelpUSHelpYou()}>
                                                                Submit
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </DialogContent>
                                            </Dialog>
                                        </Box>
                                        <div style={{ display: 'flex', flexWrap: 'wrap', rowGap:"1.806vw" }}>
                                            {this.state.important_feature.map((feature) => (
                                                <Box key={feature.name} className={feature.active ? "icon-container-business" : "icon-container-business opacity-inactive" } >
                                                    <img
                                                        src={feature.icon.url}
                                                        alt={feature.name}
                                                        className="icon-img-customisable"
                                                        style={{ display: 'block', margin: '0 auto' }}
                                                    />
                                                    <span className="fitericon-span">{feature.name}</span>
                                                </Box>
                                            ))}
                                        </div>
                                    </Paper>
                                </Grid>
                                <Grid item xs={4}>
                                    <Paper className="wrapper-2-Paper itemStyleLikes" >
                                        <Box className="itemHeadingSeeAllBusiness">
                                            <Typography style={webStyle.itemHeading}>Hours of Operation</Typography>
                                            {!this.state.editMode &&<Button className="yourfilter-edit-btn" onClick={this.handleHappyHoursEditClick}>Edit</Button>}
                                        </Box>
                                        <Box className="day-time-bar-owner">
                                            {weekdays.map((day) => (
                                                <Box className={this.state.editSchedule1Mode? "day-time-typo-bar-owner-2":"day-time-typo-bar-owner"} key={day}>
                                                    <Typography className="schedule-item-day">
                                                        {day}
                                                    </Typography>
                                                    {
                                                        this.state.editSchedule1Mode || this.state.editMode ?
                                                            <Box className="category-5-btn-container">
                                                                <Button className="category-5-btn-owner" data-test-id={this.state.displayedTimes[day].closesAt} onClick={this.handleClickOpenPopup}>
                                                                    Open
                                                                </Button>
                                                                <Button className="category-5-btn-owner">
                                                                    Close
                                                                </Button>
                                                            </Box>
                                                            :
                                                            <>
                                                             {
                                                                this.state.displayedTimes[day].opensAt !== "Closed" ? (
                                                                    <Typography className="schedule-item-time">
                                                                        {`${this.state.displayedTimes[day].opensAt} - ${this.state.displayedTimes[day].closesAt}`}
                                                                    </Typography>
                                                                ) : (
                                                                    <Typography className="schedule-item-time">
                                                                        {this.state.displayedTimes[day].closesAt}
                                                                    </Typography>
                                                                )
                                                            }
                                                            </>
                                                    }
                                                </Box>
                                            ))}
                                            <Dialog className="popup-hours" open={this.state.openPopup1} onClose={this.handleClosePopup}>
                                                <DialogTitle className="popup-heading-customform">{"Hours of Operation"}</DialogTitle>
                                                <DialogContent className="popup-hours-content">
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={4}>
                                                            <Typography className="popup-column-customform margin-opening-days">OPENING DAYS</Typography>
                                                            {weekdays.map((day) => (
                                                                <FormControlLabel
                                                                    key={day}
                                                                    className="checkbox-dropdown"
                                                                    control={
                                                                        <Checkbox
                                                                            data-test-id={day}
                                                                            className="checkbox-days"
                                                                            checked={this.state.openingDays[day] || false}
                                                                            onChange={(event) => this.handleCheckboxChange(day, event.target.checked)}
                                                                        />
                                                                    }
                                                                    label={<span className="checkbox-dropdown-label">{day}</span>}
                                                                />
                                                            ))}
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography className="popup-column-customform">OPENS AT</Typography>
                                                            {weekdays.map((day) => (
                                                                <Box key={day} display="flex" alignItems="center">
                                                                    {this.state.openingDays[day] ? (
                                                                        <TextField
                                                                            type="time"
                                                                            sx={{
                                                                                "& .MuiOutlinedInput-root": {
                                                                                    "& fieldset": {
                                                                                        borderColor: "#712220",
                                                                                    },
                                                                                    "&:hover fieldset": {
                                                                                        borderColor: "#712220",
                                                                                    },
                                                                                    "&.Mui-focused fieldset": {
                                                                                        borderColor: "#712220",
                                                                                    },
                                                                                    "& .MuiInputBase-input": {
                                                                                        padding: "0.833vw 1.111vw",
                                                                                        fontSize: "1.111vw",
                                                                                    },
                                                                                },
                                                                            }}
                                                                            data-test-id={this.state.setTimes[day]?.opensAt || ''}
                                                                            placeholder="Select opening hours"
                                                                            className="input-time-field"
                                                                            value={this.state.setTimes[day]?.opensAt || ''}
                                                                            onChange={this.handleTimeChange('setTimes', day, 'opensAt')}
                                                                            fullWidth
                                                                        />
                                                                    ) : (
                                                                        <Typography className="popup-closed-typo">CLOSED</Typography>
                                                                    )}
                                                                </Box>
                                                            ))}
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography className="popup-column-customform">CLOSES AT</Typography>
                                                            {renderTimeField('setTimes', 'openingDays')}
                                                        </Grid>
                                                    </Grid>
                                                </DialogContent>
                                                <DialogActions className="action-popup">
                                                    <Button onClick={this.handleClosePopup} className="cancel-btn">Cancel</Button>
                                                    <Button onClick={this.handleSubmitPopup} className="submit-btn-popup">Submit</Button>
                                                </DialogActions>
                                            </Dialog>

                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={4} >
                                    <Paper className="wrapper-2-Paper itemStyleLikes">
                                        <Box className="itemHeadingSeeAllBusiness">
                                            <Typography style={webStyle.itemHeading}>Happy Hours</Typography>
                                            {!this.state.editMode &&<Button className="yourfilter-edit-btn" onClick={this.handleHappyHoursEditClick2}>Edit</Button>}
                                        </Box>
                                        <Box className="day-time-bar-owner">
                                            {weekdays.map((day) => (
                                                <Box className={this.state.editSchedule2Mode? "day-time-typo-bar-owner-2":"day-time-typo-bar-owner"} key={day}>
                                                    <Typography className="schedule-item-day">
                                                        {day}
                                                    </Typography>
                                                    {
                                                        this.state.editSchedule1Mode || this.state.editMode ?
                                                            <Box className="category-5-btn-container">
                                                                <Button className="category-5-btn-owner" data-test-id={this.state.displayedTimes2[day].closesAt} onClick={this.handleClickOpenPopup2}>
                                                                    Open
                                                                </Button>
                                                                <Button className="category-5-btn-owner">
                                                                    Close
                                                                </Button>
                                                            </Box>
                                                            :
                                                            <>
                                                            {
                                                                this.state.displayedTimes2[day].opensAt !== "Closed" ? (
                                                                    <Typography className="schedule-item-time">
                                                                        {`${this.state.displayedTimes2[day].opensAt} - ${this.state.displayedTimes2[day].closesAt}`}
                                                                    </Typography>
                                                                ) : (
                                                                    <Typography className="schedule-item-time">
                                                                        {this.state.displayedTimes2[day].closesAt}
                                                                    </Typography>
                                                                )
                                                            }
                                                            </>
                                                    }
                                                </Box>
                                            ))}
                                            <Dialog className="popup-hours" open={this.state.openPopup2} onClose={this.handleClosePopup2}>
                                                <DialogTitle className="popup-heading-customform">{"Happy hours"}</DialogTitle>
                                                <DialogContent className="popup-hours-content">
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={4}>
                                                            <Typography className="popup-column-customform margin-opening-days">OPENING DAYS</Typography>
                                                            {weekdays.map((day) => (
                                                                <FormControlLabel
                                                                    key={day}
                                                                    className="checkbox-dropdown"
                                                                    data-test-id={day}
                                                                    control={
                                                                        <Checkbox
                                                                            className="checkbox-days"
                                                                         
                                                                            checked={this.state.openingDays2[day] || false}
                                                                            onChange={(event) => this.handleCheckboxChange2(day, event.target.checked)}
                                                                        />
                                                                    }
                                                                    label={<span className="checkbox-dropdown-label">{day}</span>}
                                                                />
                                                            ))}
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography className="popup-column-customform">OPENS AT</Typography>
                                                            {weekdays.map((day) => (
                                                                <Box key={day} display="flex" alignItems="center">
                                                                    {this.state.openingDays2[day] ? (
                                                                        <TextField
                                                                            type="time"
                                                                            sx={{
                                                                                "& .MuiOutlinedInput-root": {
                                                                                    "& fieldset": {
                                                                                        borderColor: "#712220",
                                                                                    },
                                                                                    "&:hover fieldset": {
                                                                                        borderColor: "#712220",
                                                                                    },
                                                                                    "&.Mui-focused fieldset": {
                                                                                        borderColor: "#712220",
                                                                                    },
                                                                                    "& .MuiInputBase-input": {
                                                                                        padding: "0.833vw 1.111vw",
                                                                                        fontSize: "1.111vw",
                                                                                    },
                                                                                },
                                                                            }}
                                                                            data-test-id={this.state.setTimes2[day]?.opensAt || ''}
                                                                            placeholder="Select opening hours"
                                                                            className="input-time-field"
                                                                            value={this.state.setTimes2[day]?.opensAt || ''}
                                                                            onChange={this.handleTimeChange('setTimes2', day, 'opensAt')}
                                                                            fullWidth
                                                                        />
                                                                    ) : (
                                                                        <Typography className="popup-closed-typo">CLOSED</Typography>
                                                                    )}
                                                                </Box>
                                                            ))}
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography className="popup-column-customform">CLOSES AT</Typography>
                                                                {renderTimeField('setTimes2', 'openingDays2')}
                                                        </Grid>
                                                    </Grid>
                                                </DialogContent>
                                                <DialogActions className="action-popup">
                                                    <Button onClick={this.handleClosePopup2} className="cancel-btn">Cancel</Button>
                                                    <Button onClick={this.handleSubmitPopup2} className="submit-btn-popup">Submit</Button>
                                                </DialogActions>
                                            </Dialog>

                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={this.getPaperWrapperClass(subscription)} >
                                        <Box className="itemHeadingSeeAllBusiness">
                                            <Typography style={webStyle.itemHeading}>Events/Specials Calendar</Typography>
                                            <Typography className="see-all-typo">Manage Events</Typography>
                                        </Box>
                                        <Box sx={{ flexGrow: 1 }}>
                                            <Grid container spacing={1}>
                                                <Grid container item spacing={3}>
                                                    {this.state.businessEvents.map((event, index) => (
                                                        <Grid item xs={3} key={index}>
                                                            <Paper className="businessEventContainer">
                                                                <div className="event-day">{event.day}</div>
                                                                <div className="event-date">{event.date}</div>
                                                                <Typography gutterBottom className="event-time">{event.time}</Typography>
                                                                <div className="event-name">{event.name}</div>
                                                            </Paper>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <NavigationMenu {...this.props} />
                </Box>
            </ThemeProvider>
            //Merge Engine End DefaultContainer
            // Customizable Area End
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    backgroundImg: {
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        display: "flex",
        justifyContent: 'center',
    },
    mainContainer: {
        marginTop: "76px",
        marginBottom: "72px",
        width: "79.87%",
    },
    imgStyle2: {
        height: "7.85vw",
        width:"7.85vw",
        borderRadius: '50%'
    },
    btnContainer: {
        display: "flex",
        justifyContent: "flex-end"
    },
    imgGridLibrary: {
        width: "6.597vw",
        height: "6.667vw",
        borderRadius: "8px",
    },
    itemHeading: {
        color: "#712220",
        fontWeight: 700,
        fontSize: "1.112vw",
        fontFamily: "Raleway, sans-serif",
    },
    profileInfo: {
        fontSize: '1.25vw',
        fontFamily: 'Raleway',
        fontWeight: 600,
        color: "#181818",
    },
    editButton: {
        backgroundColor: "#C8572C",
        fontSize: "1rem",
        textTransform: "none",
        fontFamily: 'Raleway',
    },
    radioBox: {
        color: "712220",
        padding: 0,
    },
    radioBox1:{
        padding:"0px 0.556vw 0px 0px",
    },
    dropDownStyle: {
        width: "13.264vw",
        minWidth:"13.264vw",
        height: "3.125vw",
        color: "#712220",
        fontSize:"0.972vw",
    },
    starTextStyle: {
        lineHeight: "18px",
        fontFamily: "Raleway",
        fontSize: "1.111vw",
        color: "#712220",
        marginLeft: "0.486vw",
        fontWeight: 700,
    },
    subscriptionContainer: {
        display:"flex",
        color:"#FFF",
        alignItems:"end",
        justifyContent:"space-between"
    },
    subscriptionBtnContainer: {
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems: "center",
    },
    subscriptionHeading: {
        fontWeight: 600,
        fontFamily: 'Museo Sans',
        fontSize: "1.528vw",
    },
    unlockHeading: {
        fontWeight: 700,
        fontFamily: 'Raleway',
        fontSize: "1.111vw"
    },
    upgradeBtn: {
        backgroundColor:"#FFFFFF",
        color:"#712220",
        fontFamily: 'Raleway',
        width:"6.458vw",
        height:"3.056vw",
        fontWeight:700,
        fontSize:"0.972vw",
        padding:0,
        letterSpacing:'1px',
        borderRadius:"8px"
    },
    btnGroupStyle: {
        fontFamily: 'Raleway',
        fontWeight: 700,
        fontSize:"0.972vw",
        letterSpacing:"1.1px",
        borderRadius:"0px",
        width:"16.806vw",
        cursor:"default",
    }
}
// Customizable Area End