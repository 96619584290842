import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Button,
  CardMedia,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Alert from '@mui/material/Alert';
import Header from "../../navigationmenu/src/Header.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu";
import { group, groupImages, groupImg1, groupImg2, groupLanding, imageBar, imageBarHopponBingo } from "./assets";
import './ContactUs.css'
// Customizable Area End

import ContactusController, { Props } from "./ContactusController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box style={{display:'flex', flexDirection:'column', overflowX:'hidden'}}>
          <Header {...this.props} />
            <Box sx={{marginTop:'5.625vw'}}>
              <img className="backGround1" src={groupLanding} alt="group_landing"/>

              <Box sx={{ 
                width:'100%',display:'flex', paddingTop:"4.375vw", paddingBottom:"2.292vw",
                flexDirection:'column', alignItems:'center', justifyContent:'center',
              }}>
              <div className="preview">Preview</div>
              <div className="appFeatures">App features</div>
              </Box>

              <Box sx={{ 
                width:'100%', display:'flex',
                alignItems:'center', justifyContent:'center',
              }}>
              <img 
                style={{width:'100%', padding:'0px 5.972vw'}} 
                src={groupImages} alt="group_Images"
              />
              </Box>

            <Box sx={{
              display: 'flex', flexDirection: 'column',
              justifyContent: 'center', padding: '13.681vw 6.875vw 16.042vw 6.944vw', alignItems: 'center'
            }}>
              <div className="headingNew">What's New</div>
              <Box style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
              {this.state.travelArticleData?.map((article: any) => (
                <Box style={{display:"flex", flexDirection:"column", width:"27.014vw", cursor:"pointer"}} onClick={this.handleReadMore}>
                  <CardMedia
                    component="img"
                    alt={article.title}
                    image={article.blog_image?.url}
                    className="blog-card-img-landing"
                  />
                  <Box className="blog-img-content-landing">
                      <Typography className="blog-content-font blog-category-container-2">{article.category}</Typography>
                  </Box>
                  <Box className="blog-card-content-container">
                    <Typography className="typo-heading-blog-landing" gutterBottom>
                      {article.title}
                    </Typography>
                    <Typography gutterBottom className="typo-description-blog">
                      <div dangerouslySetInnerHTML={{ __html: article?.description?.split(/[\.\!]/)[0] + '.' || ' ' }} />
                    </Typography>
                    <Box className="blog-content">
                      <Box className="blog-content-1">
                        <Typography className="blog-content-font blog-author-container-2" style={{fontWeight:600}}>{article.author_name}</Typography>
                        <Typography className="blog-content-font blog-date-container-2">{article.date}</Typography>
                      </Box>
                    </Box>
                    <Typography className="blog-content-font-landing blog-read-more-container-2" data-test-id="articleBlogBtn" >READ MORE</Typography>
                  </Box>
                </Box>
              ))}
              </Box>
            </Box>

              <Box style={{display:'flex', padding:'10px', justifyContent:'space-between'}}>
                <div className="box1">
                  <img style={{width:'74.27%'}} className="imgBox" src={groupImg1} alt="groupImg1"/>
                  <img className="imgBox" src={imageBarHopponBingo} alt="groupImg1"/>
                </div>
                <div className="box2">
                  <img className="imgBox" src={imageBar} alt="groupImg1"/>
                  <img style={{width:'67.5%', marginLeft:"7.083vw"}} className="imgBox" src={groupImg2} alt="groupImg1"/>
                </div>
              </Box>
                <Container style={{
                  width:'100%', display:'flex', 
                  alignItems:'center', justifyContent:'center'}}>
                <Box sx={{
                  display:'flex', flexDirection:'column', justifyContent:'center', padding:'50px',
                  alignItems:'center', width:'47.083vw', height:'25vw', gridGap:'15px'
                }}>
                  <div className="contactUsTxt">Contact Us</div>
                  <div className="nameEmailCnt">
                    <input 
                      className="inputFields" type="text" 
                      name="name" placeholder="Name"
                      value={this.state.name}
                      data-test-id={'contactUsName'}
                      onChange={(e) =>
                        this.setName(e.target.value)
                      }
                    />
                    <input 
                      className="inputFields" 
                      type="email" 
                      name="email" 
                      value={this.state.email}
                      placeholder="Email"
                      data-test-id={'contactUsEmail'}
                      onChange={(e) =>
                        this.setEmail(e.target.value)
                      }
                    />
                  </div>
                  <textarea 
                    name="message" id="messageTextArea" 
                    placeholder="Message"
                    data-test-id={'contactUsDes'}
                    required
                    value={this.state.comments}
                    onChange={(e) =>
                      this.setComments(e.target.value)
                    }
                  >
                  </textarea>
                  <div className="nameEmailCnt">
                    {this.state.responsePostApi && !this.state.errors && this.state.isAlertVisible && <Alert sx={{ bgcolor: "transparent" }}  severity="success" className="successfulMsg">Request submitted successfully!</Alert>}
                    {this.state.errors && !this.state.responsePostApi && <Alert sx={{ bgcolor: "transparent" }} severity="error">{this.state.errors} </Alert> }
                    <div></div>
                    <Button
                      className="submitBtn"
                      type="button"
                      data-test-id={'contactSubmitBtn'} onClick={() => {
                        this.handleClick();
                      }}>
                      Submit
                    </Button>
                   </div>
                </Box>
              </Container>
            </Box>
          <NavigationMenu {...this.props}/>
        </Box>
      </ThemeProvider>
      // Customizable Area Start
    );
  }
}

// Customizable Area Start

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// Customizable Area End
